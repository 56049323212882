import {
  CreateAreaEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { Alert, FaroButton, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Box, Stack, SxProps, Theme } from "@mui/material";
import {
  OpenImageFileSelectControl,
  SheetImagePreview,
  SheetUploadControls,
} from "./create-area-body-parts";
import { useCreateAreaLogic } from "./use-create-area-logic";

type CreateAreaMainBodyProps = {
  /** Optional styling applied to the component */
  sx?: SxProps<Theme>;

  /** true if sheet is a first one for the project */
  isFirstSheet?: boolean;
};

/**
 * @returns a component containing the input element to choose the name of the image,
 * the file drop handler and the button to create the sheet
 */
export function CreateAreaMainBody({
  sx,
  isFirstSheet = false,
}: CreateAreaMainBodyProps): JSX.Element {
  const {
    file,
    inputName,
    inputNameError,
    uploadProgress,
    hasDefaultGrid,
    hasImgBeenResized,
    setInputName,
    setFile,
    setDefaultGrid,
    createSheet,
    cancelUpload,
    widthToHeightRatio,
    rotation,
    setRotation,
    setCrop,
    numberOfPdfPages,
    onUpdateSelectedPdfPage,
    selectedPdfPage,
  } = useCreateAreaLogic();

  return (
    <Stack sx={sx} marginBottom={4}>
      {isFirstSheet && (
        <FaroText variant="heading20" sx={{ mb: "0.875rem" }}>
          Import first sheet
        </FaroText>
      )}

      {hasImgBeenResized && (
        <Alert
          title="Image will be automatically rescaled."
          description="The image exceeds the maximum supported resolution. The quality might be affected."
          variant="warning"
        />
      )}

      <SheetUploadControls
        file={file}
        inputName={inputName}
        uploadProgress={uploadProgress}
        inputNameError={inputNameError}
        onSetInputName={setInputName}
        numberOfPdfPages={numberOfPdfPages}
        selectedPdfPage={selectedPdfPage}
        onUpdateSelectedPdfPage={onUpdateSelectedPdfPage}
        onCancelUpload={cancelUpload}
        onCreateSheet={createSheet}
      />

      <Box component="div" mt="24px" width="100%" bgcolor={neutral[100]}>
        {file ? (
          <SheetImagePreview
            file={file}
            rotation={rotation}
            setRotation={setRotation}
            setCrop={setCrop}
            widthToHeightRatio={widthToHeightRatio}
            uploadProgress={uploadProgress}
          />
        ) : (
          <OpenImageFileSelectControl onSetFile={setFile} />
        )}
      </Box>

      {!file && hasDefaultGrid && (
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt="1rem"
          gap={2}
        >
          <FaroText variant="bodyM" sx={{ color: "gray850" }}>
            Don't have an image on hand? Click on the <b>Use grid</b> button to
            get started right away.
          </FaroText>
          <FaroButton
            aria-label="use grid"
            variant="ghost"
            size="m"
            onClick={() => {
              Analytics.track<CreateAreaEventProperties>(
                EventType.setFloorImage,
                {
                  via: "default-grid",
                  extension: "",
                },
              );

              setDefaultGrid();
            }}
            sx={{ whiteSpace: "nowrap" }}
          >
            Use grid
          </FaroButton>
        </Stack>
      )}
    </Stack>
  );
}
