import { parseImageFromFile, resizeImage, useToast } from "@faro-lotv/flat-ui";
import { assert, getFileExtension } from "@faro-lotv/foundation";
import * as PDFJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.mjs?url";
import { useCallback, useState } from "react";
import {
  MAX_ALLOWED_IMG_HEIGHT,
  MAX_ALLOWED_IMG_WIDTH,
} from "./create-area-utils";

// Deploy and register the pdfjs worker
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Default to render only the first page for now
const DEFAULT_PAGE_TO_RENDER = 1;

export type UseParseUserImageReturn = {
  /** The image file to upload computed by the user selected file */
  parsedImage?: File;

  /** True, if the image has been resized to allowed size */
  hasImgBeenResized: boolean;

  /**
   * Register the file selected by the user
   *
   * @param f file with image
   * @param pageNumber selected page number (numerated from 1 to numberOfPdfPages); undefined if file is not PDF
   */
  parseImage(f?: File, pageNumber?: number): void;

  /** total number of pages in PDF document; zero if file is not *.pdf */
  numberOfPdfPages?: number;

  /** ratio width/height of original image - used to scale rotated image in preview  */
  widthToHeightRatio: number;
};

/**
 * Provides a local state to capture the user selected file, and convert it to
 * a format that can be uploaded as a floor image to the project
 *
 * @returns an instance of the UseParseUserImageReturn with the data to use in the component
 */
export function useParseUserImage(): UseParseUserImageReturn {
  const [parsedImage, setParsedImage] = useState<File>();
  const [hasImgBeenResized, setHasImgBeenResized] = useState<boolean>(false);
  const [numberOfPdfPages, setNumberOfPdfPages] = useState<number | undefined>(
    undefined,
  );
  const [widthToHeightRatio, setWidthToHightRatio] = useState(1);
  const { openToast } = useToast();

  const parseImage = useCallback(
    async (input?: File, pageNumber?: number) => {
      setWidthToHightRatio(1);

      // Input will be undefined if the selected image is reset
      if (!input) {
        setParsedImage(undefined);
        return;
      }

      if (getFileExtension(input.name) !== "pdf") {
        setNumberOfPdfPages(undefined);
        const { width, height } = await parseImageFromFile(input);

        setWidthToHightRatio(width / height);

        if (width > MAX_ALLOWED_IMG_WIDTH || height > MAX_ALLOWED_IMG_HEIGHT) {
          // The user uploaded image has exceeded the maximum allowed size, hence resizing the image
          const compressedFile = await resizeImage(input, {
            maxSize: Math.max(MAX_ALLOWED_IMG_WIDTH, MAX_ALLOWED_IMG_HEIGHT),
          });
          setParsedImage(compressedFile);
          setHasImgBeenResized(true);
        } else {
          setParsedImage(input);
        }
        return;
      }
      convertPdfToImage(
        input,
        pageNumber ?? DEFAULT_PAGE_TO_RENDER,
        setNumberOfPdfPages,
        setWidthToHightRatio,
      )
        .then(setParsedImage)
        .catch(() => {
          openToast({
            title:
              "Extracting a floor image from the pdf failed, please try with a different file",
            variant: "error",
            persist: false,
          });
        });
    },
    [openToast],
  );

  return {
    parsedImage,
    hasImgBeenResized,
    widthToHeightRatio,
    parseImage,
    numberOfPdfPages,
  };
}

/**
 * Convert the first image of a pdf file to a jpeg file
 *
 * @param input the pdf file selected by the user
 * @param pageNumber page in PDF selected by user
 * @param setPagesCnt callback to set number of pages
 * @param setWidthToHightRatio image width to hight ratio
 * @returns a File object with the generated jpeg image from the first page of the pdf file
 */
async function convertPdfToImage(
  input: File,
  pageNumber: number,
  setPagesCnt: (n: number) => void,
  setWidthToHightRatio: (ratio: number) => void,
): Promise<File> {
  // Read the file content and parse it
  const content = await input.arrayBuffer();
  const pdfDoc = await PDFJS.getDocument(content).promise;

  const { numPages } = pdfDoc;
  setPagesCnt(numPages);

  assert(pageNumber <= numPages, "Invalid page requested");
  const page = await pdfDoc.getPage(pageNumber);

  // Get the original viewport at scale = 1
  const viewport = page.getViewport({ scale: 1 });

  // Calculate the scale to achieve the desired size matching to max supported width/height (use one which is bigger)
  const scale =
    viewport.width > viewport.height
      ? MAX_ALLOWED_IMG_WIDTH / viewport.width
      : MAX_ALLOWED_IMG_HEIGHT / viewport.height;

  // Create a new viewport with the calculated scale
  const scaledViewport = page.getViewport({ scale });

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  assert(
    ctx,
    "Unable to create an offscreen 2d context to render the pdf image",
  );

  // Set canvas dimensions based on the scaled viewport
  canvas.width = scaledViewport.width;
  canvas.height = scaledViewport.height;

  setWidthToHightRatio(scaledViewport.width / scaledViewport.height);

  // Render and convert the pdf page to a jpeg blob
  await page.render({ canvasContext: ctx, viewport: scaledViewport }).promise;
  const blob = await new Promise<Blob>((resolve, reject) =>
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
        return;
      }
      reject(new Error("Unable to convert pdf to image"));
    }),
  );

  return new File([blob], input.name.replace(".pdf", ".png"));
}
